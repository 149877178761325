'use client'
import { Htmldiv } from "@/app/(web)/_components/home/subcomponents/footer/home"
import { TWButton } from "@/components/tailwind/Button"
import { ToolsContext } from "@/context/tools/tools"
import parse from "html-react-parser"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { BsPlus } from "react-icons/bs"
import { IoIosRemove } from "react-icons/io"
export const FooterCatalogue = props => {
  const { catalogue, hostname: { colors }, pageTexts, category } = props
  const info = category ? catalogue.info : catalogue.webdata
  const hgtRef = useRef()
  const [ more, setMore ] = useState(false)
  const [ show, setShow ] = useState(false)
  const { tools: { texts }} = useContext(ToolsContext)
  const globalTexts = useMemo(() => texts.generic, [texts])
  useEffect(() => {
    if (hgtRef.current?.clientHeight > 125) {
      setShow(true)
    }
  })
  return (
    <div id="catalogue-info" className="mx-auto mt-5">
      <div className="grid grid-cols-1 tablet:hidden">
        <span style={{ fontSize: 25, fontWeight: 700 }} >
          {parse(info.h1 || ``)}
        </span>
        <span style={{ fontSize: 17, fontWeight: 600 }}>
          {parse(info.h2 || ``)}
        </span>
      </div>
      <span style={{ fontSize: 15, fontWeight: 500 }}>
        {parse(info.h3 || ``)}
      </span>
      <div ref={hgtRef} className={`${more || !show ? `h-auto` : `max-h-32 desc-shadow`}`}>
        <Htmldiv {...{ colors, font: 15 }}>
          {parse(info.description?.replace(/font-family/g, `family`).replace(`&gt;`, `>`).
            replace(`&lt;`, `<`) || ``)}
        </Htmldiv>
      </div>
      {info?.description !== `` && show && (
        <TWButton
          {...{ colors }}
          color={colors.main}
          aria-label="more-info"
          style={{ marginTop: `5px`, padding: `0 10px 0 5px`, border: `1px solid ${colors.lightGrey3}` }}
          type="outlined"
          onClick={() => setMore(!more)}
          animated
        >
          { more ? <IoIosRemove style={{ fontSize: `15px` }}/> : <BsPlus style={{ fontSize: `15px` }}/>}
          <p style={{ fontSize: `13px` }}>{globalTexts?.info ?? ``}</p>
        </TWButton>
      )}
    </div>
  )
}
